<template>
  <div id="slideWrap">
    <div class="swiper-container mainSlide">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in banner" v-bind:key="item.bannerIdx">
          <a><!-- :href="item.domain" -->
            <img :src="item.bannerImg" alt="slide">
          </a>
        </div>
        <div class="swiper-slide">
          <img src="../../assets/img/main/main_PC_01.png">
        </div>
        <div class="swiper-slide">
          <img src="../../assets/img/main/main_PC_02.png">
        </div>
      </div>
      <!-- <div class="swiper-button-prev" role="button"></div>
      <div class="swiper-button-next" role="button"></div> -->
    </div>
    <div class="swiper-pagination swiper-pagination-main">
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'UiSwiper',
  title: 'Navigation',
  data () {
    return {
      bannerList: [
      ]
    }
  },
  mounted () {
    this.loadSwiper()
  },
  watch: {
    banner () {
      if (this.banner) {
        this.loadSwiper()
      }
    }
  },
  computed: {
    ...mapState([
      'banner'
    ])
  },
  methods: {
    loadSwiper () {
      // const list = this.banner
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.swiper-container', {
        spaceBetween: 30,
        autoplay: { // 자동 슬라이드 설정 , 비 활성화 시 false
          delay: 4000, // 시간 설정
          disableOnInteraction: false // false로 설정하면 스와이프 후 자동 재생이 비활성화 되지 않음
        },
        loop: true,
        observer: true,
        observeParents: true,

        // If we need pagination
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          clickable: true,
          el: '.swiper-pagination-main'
        }
        // pagination: {
        //  el: '.swiper-pagination',
        //  clickable: true,
        //  renderBullet: function (index, className) {
        //    const title = list[index].bannerTitle || ''
        //    return '<div class="' + className + '"><span>' + title + '</span></div>'
        //  }
        // }
      })

      if (this.banner) {
        setTimeout(function () {
          this.$emit('loaded', true)
        }.bind(this), 500)
      }
    }
  }
}
</script>

<style src="@/styles/swiper.min.css"></style>

<style>
#slideWrap {position: relative;width: 1314px; margin: 0 auto;}
#slideWrap .swiper-pagination-main {bottom: -20px; right: 0; display: flex; gap: 10px;}
.swiper-pagination-main .swiper-pagination-bullet {width: 8px; height:8px; background: #fff; opacity: 1; box-shadow: 0px 2px 2px #0000004D; }
.swiper-pagination-main .swiper-pagination-bullet-active {background: #a9aab3; }

.mainSlide {height: 330px; margin-top: 40px;}
.mainSlide .swiper-slide img {width: 100%;}

.s_ment {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #413838;width: 80%;max-width: 1314px;margin: 0 auto;}
.s_ment h2 {font-size: 12pt;color: #e5972d;margin-bottom: 15px;}
.s_ment h1 {font-size: 20pt;margin-bottom: 20px;}
.s_ment h3 {font-size: 12pt;}

@media screen and (max-width: 800px) {
  #slideWrap { width: 100%; margin: 0 auto;}
  .mainSlide { height: 200px; margin-top: 10px; }
  .mainSlide .swiper-slide img {width: auto;}
  .mainSlide img { height: 250px; }
}
</style>
